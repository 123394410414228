export const adminRouteName = {
  root: 'admin',
  members: 'admin-members',
  memberView: 'admin-member-view',
  channelAccount: 'admin-channel-account',
  channelAccountView: 'admin-channel-account-view',
  slot: 'admin-slot',
  slotView: 'admin-slot-view',
  adAccount: 'admin-ad-account',
  adAccountView: 'admin-ad-account-view',
  campaign: 'admin-campaign',
  campaignView: 'admin-campaign-view',
}

const channel = [
  {
    path: '/channel-account',
    name: adminRouteName.channelAccount,
    component: () => import('@/views/pages/admin/account/AccountList.vue'),
    meta: {
      layout: 'horizontal',
      resource: 'Admin',
      navActiveLink: adminRouteName.channelAccount,
    },
  },
  {
    path: '/channel-account/:channelId',
    name: adminRouteName.channelAccountView,
    component: () => import('@/views/pages/admin/account/AccountView.vue'),
    meta: {
      layout: 'horizontal',
      resource: 'Admin',
      navActiveLink: adminRouteName.channelAccount,
    },
  },
  {
    path: '/slot',
    name: adminRouteName.slot,
    component: () => import('@/views/pages/admin/slot/SlotListAdmin.vue'),
    meta: {
      layout: 'horizontal',
      resource: 'Admin',
      navActiveLink: adminRouteName.slot,
    },
  },
  {
    path: '/slot/:slotId',
    name: adminRouteName.slotView,
    component: () => import('@/views/pages/admin/slot/SlotViewAdmin.vue'),
    meta: {
      layout: 'horizontal',
      resource: 'Admin',
      navActiveLink: adminRouteName.slot,
    },
  },
]

const ad = [
  {
    path: '/ad-account',
    name: adminRouteName.adAccount,
    component: () => import('@/views/pages/admin/account/AccountList.vue'),
    meta: {
      layout: 'horizontal',
      resource: 'Admin',
      navActiveLink: adminRouteName.adAccount,
    },
  },
  {
    path: '/ad-account/:adId',
    name: adminRouteName.adAccountView,
    component: () => import('@/views/pages/admin/account/AccountView.vue'),
    meta: {
      layout: 'horizontal',
      resource: 'Admin',
      navActiveLink: adminRouteName.adAccount,
    },
  },
  {
    path: '/campaign',
    name: adminRouteName.campaign,
    component: () => import('@/views/pages/admin/campaign/CampaignListAdmin.vue'),
    meta: {
      layout: 'horizontal',
      resource: 'Admin',
      navActiveLink: adminRouteName.campaign,
    },
  },
  {
    path: '/campaign/:campaignId',
    name: adminRouteName.campaignView,
    component: () => import('@/views/pages/admin/campaign/CampaignViewAdmin.vue'),
    meta: {
      layout: 'horizontal',
      resource: 'Admin',
      navActiveLink: adminRouteName.campaign,
    },
  },
]

const member = [
  {
    path: '/members',
    name: adminRouteName.members,
    component: () => import('@/views/pages/admin/members/MemberList.vue'),
    meta: {
      layout: 'horizontal',
      resource: 'Admin',
      navActiveLink: adminRouteName.members,
    },
  },
  {
    path: '/members/:memberId',
    name: adminRouteName.memberView,
    component: () => import('@/views/pages/admin/members/MemberView.vue'),
    meta: {
      layout: 'horizontal',
      resource: 'Admin',
      navActiveLink: adminRouteName.members,
    },
  },
]

export default [
  {
    path: '/admin',
    name: adminRouteName.root,
    component: () => import('@/views/pages/admin/AdminMain.vue'),
    meta: {
      layout: 'horizontal',
      resource: 'Admin',
    },
  },
  ...member,
  ...channel,
  ...ad,
]
