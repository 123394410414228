export const authRouteName = {
  login: 'auth-login',
  register: 'auth-register',
  forgotPassword: 'auth-forgot-password',
  notAuthorized: 'auth-not-authorized',
  noAccount: 'no-account',
}

export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      // 이미 로그인 된 유저는 다시 로그인 화면으로 진입할 수 없음
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/pages/authentication/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      // 이미 로그인 된 유저는 회원가입 화면으로 진입할 수 없음
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/not-authorized',
    name: 'auth-not-authorized',
    component: () => import('@/views/pages/authentication/NotAuthorized.vue'),
    meta: {
      layout: 'horizontal',
      resource: 'Auth',
    },
  },
  {
    path: '/no-account',
    name: 'no-account',
    component: () => import('@/views/pages/authentication/NoAccount.vue'),
    meta: {
      layout: 'horizontal',
      resource: 'Auth',
    },
  },
]
