export const queryEndpoint = {
  channels: '/channels/',
}

export const accountEndpoint = {
  ad: '/ad-account',
  adView: id => `/ad-account/${id}`,
  adMember: (accountId, memberId) => `/ad-account/${accountId}/member/${memberId}`,
  channel: '/channel-account',
  channelView: id => `/channel-account/${id}`,
  channelMember: (accountId, memberId) => `/channel-account/${accountId}/member/${memberId}`,
}

export const campaignEndpoint = {
  list: adAccountId => `${accountEndpoint.ad}/${adAccountId}/campaign`,
  view: campaign => `${accountEndpoint.ad}/${campaign.adAccountId}/campaign/${campaign.id}`,
  content: (campaign, content) => `${accountEndpoint.ad}/${campaign.adAccountId}/campaign/${campaign.id}/content/${content.id}`,
  attachment: (campaign, content) => `${accountEndpoint.ad}/${campaign.adAccountId}/campaign/${campaign.id}/content/${content.id}/attachment`,
  review: campaign => `${accountEndpoint.ad}/${campaign.adAccountId}/campaign/${campaign.id}/review`,
}

export const slotEndPoint = {
  list: channelAccountId => `${accountEndpoint.channel}/${channelAccountId}/slot`,
  view: slot => `${accountEndpoint.channel}/${slot.channelAccountId}/slot/${slot.id}`,
  attachment: slot => `${accountEndpoint.channel}/${slot.channelAccountId}/slot/${slot.id}/upload`,
  review: slot => `${accountEndpoint.channel}/${slot.channelAccountId}/slot/${slot.id}/review`,
}

export const adminEndpoint = {
  members: '/admin/members/',
  slots: '/admin/slot',
  campaigns: '/admin/campaign',
  channelAccounts: '/admin/channel-accounts/',
  adAccounts: '/admin/ad-accounts/',
}
