export default {
  // Endpoints
  loginEndpoint: '/member/sign-in',
  registerEndpoint: '/member/sign-up',
  refreshEndpoint: '/member/access-token',
  logoutEndpoint: '/member/sign-out',
  findUserEndpoint: '/member/find',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
