export const initialAbility = [
  {
    action: 'read',
    subject: 'Auth',
  },
]

export const adminAbility = [
  {
    action: 'manage',
    subject: 'Auth',
  },
  {
    action: 'manage',
    subject: 'Admin',
  },
]

export const channelAbility = permission => {
  if (permission === 'WRITE') {
    return [
      ...initialAbility,
      {
        action: 'read',
        subject: 'Channel',
      },
      {
        action: 'write',
        subject: 'Channel',
      },
    ]
  }
  return [
    ...initialAbility,
    {
      action: permission.toLowerCase(),
      subject: 'Channel',
    },
  ]
}

export const adAbility = permission => {
  if (permission === 'WRITE') {
    return [
      ...initialAbility,
      {
        action: 'read',
        subject: 'Ad',
      },
      {
        action: 'write',
        subject: 'Ad',
      },
    ]
  }
  return [
    ...initialAbility,
    {
      action: permission.toLowerCase(),
      subject: 'Ad',
    },
  ]
}
