import Vue from 'vue'
import VueRouter from 'vue-router'

// route protection
import { canNavigate } from '@/libs/acl/protection'
import { isUserLoggedIn, getHomeRouteForLoggedInUser } from '@/auth/utils'
import { getUserData } from '@/auth/storage/handlers'

// Routes
import auth, { authRouteName } from './routes/auth'
import channel from './routes/channel'
import ad from './routes/ad'
import admin from './routes/admin'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: { name: authRouteName.login },
    },
    { path: '*', redirect: 'error-404' },
    ...auth,
    ...channel,
    ...ad,
    ...admin,
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) {
      return next({ name: 'auth-login' })
    }
    // If logged in => not authorized
    return next({ name: 'auth-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    return next(getHomeRouteForLoggedInUser(userData ? userData.role : 'ROLE_USER'))
  }

  return next()
})

export default router
