<template>
  <b-card>
    <div class="loading-spinner-wrapper">
      <b-spinner
        variant="primary"
      />
    </div>
  </b-card>
</template>

<script>
import {
  BSpinner, BCard,
} from 'bootstrap-vue'

export default {
  name: 'LoadingComponent',
  components: {
    BSpinner,
    BCard,
  },
}
</script>

<style lang="scss">
.loading-spinner-wrapper {
  display: flex;
  flex-basis: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  align-items: center;
  justify-content: center;
}
</style>
