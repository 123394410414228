// eslint-disable-next-line import/prefer-default-export
export const storageKey = {
  // Value of this property will be used as key to store in storage
  userData: 'userData',
  channelAccounts: 'channelAccounts',
  adAccounts: 'adAccounts',
  lastAccount: 'lastAccount',
  channelData: 'cda',
  adData: 'ada',
  adminData: 'adm_da',
}
