import axios from '@axios'
// eslint-disable-next-line import/named
import { adminEndpoint } from '@/store/projectX/queryEndpoint'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchMembers(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(adminEndpoint.members, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMember(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${adminEndpoint.members}${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateMember(ctx, member) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${adminEndpoint.members}`, { member })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchChannelAccounts(ctx, { params }) {
      return new Promise((resolve, reject) => {
        axios
          .get(adminEndpoint.channelAccounts, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAdAccounts(ctx, { params }) {
      return new Promise((resolve, reject) => {
        axios
          .get(adminEndpoint.adAccounts, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSlotList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(adminEndpoint.slots, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSlot(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${adminEndpoint.slots}/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCampaignList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(adminEndpoint.campaigns, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCampaign(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${adminEndpoint.campaigns}/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
