import useJwt from '@/auth/jwt/useJwt'
import { channelRouteNames } from '@/router/routes/channel'
import { adRouteNames } from '@/router/routes/ad'
import { adminRouteName } from '@/router/routes/admin'
import { authRouteName } from '@/router/routes/auth'
import { getLastAccount, getUserData } from '@/auth/storage/handlers'

export const isUserLoggedIn = () => getUserData() && useJwt.getToken()

export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'ROLE_ADMIN') {
    return { name: adminRouteName.root }
  }
  if (userRole === 'ROLE_USER') {
    const lastAccount = getLastAccount()
    if (lastAccount) {
      if (lastAccount.type === channelRouteNames.root) {
        return { name: channelRouteNames.root, params: { channelId: lastAccount.id } }
      }
      return { name: adRouteNames.root, params: { adId: lastAccount.id } }
    }

    return { name: authRouteName.noAccount }
  }
  return { name: authRouteName.login }
}
