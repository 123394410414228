import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'http://localhost:3000/',
  baseURL: 'https://projectx-api.t-event.co.kr/',
  // timeout: 1000,
  // headers: { 'x-access-token': '' },
})

Vue.prototype.$http = axiosIns

export default axiosIns
