import axios from '@axios'
import { slotEndPoint } from '@/store/projectX/queryEndpoint'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSlotList(ctx, { channelAccountId, q }) {
      return new Promise((resolve, reject) => {
        axios
          .get(slotEndPoint.list(channelAccountId), { params: q })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSlot(ctx, { channelAccountId, slotId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(slotEndPoint.view({ channelAccountId, id: slotId }))
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadFile(ctx, { slot, file }) {
      return new Promise((resolve, reject) => {
        axios
          .put(slotEndPoint.attachment(slot), file, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createSlot(ctx, slot) {
      return new Promise((resolve, reject) => {
        axios
          .post(slotEndPoint.list(slot.channelAccountId), slot)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateSlot(ctx, slot) {
      return new Promise((resolve, reject) => {
        axios
          .put(slotEndPoint.view(slot), slot)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateSlotStatus(ctx, slot) {
      return new Promise((resolve, reject) => {
        axios
          .patch(slotEndPoint.view(slot), { status: slot.status })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteSlot(ctx, slot) {
      return new Promise((resolve, reject) => {
        axios
          .delete(slotEndPoint.view(slot))
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    reviewSlot(ctx, { slot, review }) {
      return new Promise((resolve, reject) => {
        axios
          .post(slotEndPoint.review(slot), review)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    requestReview(ctx, slot) {
      return new Promise((resolve, reject) => {
        axios
          .patch(slotEndPoint.review(slot))
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
