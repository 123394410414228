import ability from './ability'

export const canNavigate = to => to.matched.some(route => ability.can(route.meta.action || 'read', route.meta.resource))

export const canWriteSlot = () => ability.can('write', 'Channel')

export const canManageChannelAccount = () => ability.can('manage', 'Channel')

export const canWriteCampaign = () => ability.can('write', 'Ad')

export const canManageAdAccount = () => ability.can('manage', 'Ad')

export const isManager = () => ability.can('manage', 'Admin')
