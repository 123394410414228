<template>
  <div class="misc-wrapper">
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{ error.name }}
        </h2>
        <p class="message">
          {{ error.message }}
        </p>
        <b-button
          class="mx-1"
          @click="reload"
        >
          새로고침
        </b-button>
        <b-button
          class="mx-1"
          @click="goBack"
        >
          돌아가기
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BButton } from 'bootstrap-vue'

export default {
  name: 'ErrorComponent',
  components: {
    BButton,
  },
  props: {
    error: {
      type: Error,
      default: () => ({
        code: 'Error-404',
        message: '예상치 못한 에러가 발생했습니다.',
      }),
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    reload() {
      this.$router.go()
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/pages/page-misc';
.message {
  text-align: center;
  white-space: pre-wrap;
  margin-top: 80px;
  margin-bottom: 40px;
  line-height: 2rem;
}
</style>
