import { Ability } from '@casl/ability'
import { channelRouteNames } from '@/router/routes/channel'
import { adRouteNames } from '@/router/routes/ad'
import { isUserLoggedIn } from '@/auth/utils'
import { getLastAccount, getUserData } from '@/auth/storage/handlers'
import {
  // eslint-disable-next-line import/named
  adAbility, adminAbility, channelAbility, initialAbility,
} from './config'

// 네비게이션 메뉴를 디스플레이 하기 위한 롤 적용
// 앱 시작할 때, 새로고침 시 수행되며 update call 할때 수행
export function getAbility() {
  if (isUserLoggedIn()) {
    if (getUserData().role === 'ROLE_ADMIN') {
      return adminAbility
    }

    const lastAccount = getLastAccount()
    if (lastAccount) {
      // 마지막 사용한 계정 정보가 있는 경우 해당 계정에 맞는 네비게이션바 권한 설정
      if (lastAccount.type === channelRouteNames.root) {
        return channelAbility(lastAccount.permission)
      }
      if (lastAccount.type === adRouteNames.root) {
        return adAbility(lastAccount.permission)
      }
    }
  }
  // UserData 없는 경우 authentication 관련 페이지만 방문할 수 있음
  return initialAbility
}

export default new Ability(getAbility())
