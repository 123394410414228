<template>
  <b-popover
    :target="target"
    :title="title"
    triggers="hover focus"
  >
    <div class="guide-content">
      <slot />
    </div>
  </b-popover>
</template>

<script>
import { BPopover } from 'bootstrap-vue'

export default {
  name: 'GuidePopover',
  components: {
    BPopover,
  },
  props: {
    target: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: 'User Guide',
    },
  },
  setup() {

  },
}
</script>

<style lang="scss" scoped>
.guide-content {
  margin: 10px;
  font-size: 13px;
}
</style>
