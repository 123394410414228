import { storageKey } from '@/auth/storage/config'
import { authRouteName } from '@/router/routes/auth'

export const adRouteNames = {
  root: 'ad',
  account: 'ad-account',
  campaign: 'ad-campaign',
  campaignView: 'ad-campaign-view',
  registerCampaign: 'ad-campaign-register',
  report: 'ad-report',
}

const checkRoute = (to, from, next) => {
  if (to.params.adId.toString() === JSON.parse(localStorage.getItem(storageKey.lastAccount)).id.toString()) {
    return next()
  }
  const adAccounts = JSON.parse(localStorage.getItem(storageKey.adAccounts))
  const index = adAccounts.findIndex(a => a.id.toString() === to.params.adId.toString())
  if (index !== -1) {
    const account = adAccounts[index]
    localStorage.setItem(storageKey.lastAccount, JSON.stringify(account))
    return next()
  }
  return next({ name: authRouteName.notAuthorized })
}

const campaign = [
  {
    path: 'campaign',
    name: adRouteNames.campaign,
    component: () => import('@/views/pages/ad/campaign/CampaignList.vue'),
    meta: {
      layout: 'horizontal',
      resource: 'Ad',
      navActiveLink: adRouteNames.campaign,
    },
    beforeEnter: checkRoute,
  },
  {
    path: 'campaign/:campaignId',
    name: adRouteNames.campaignView,
    component: () => import('@/views/pages/ad/campaign/CampaignView.vue'),
    meta: {
      layout: 'horizontal',
      resource: 'Ad',
      navActiveLink: adRouteNames.campaign,
    },
  },
  {
    path: 'campaign-register',
    name: adRouteNames.registerCampaign,
    component: () => import('@/views/pages/ad/campaign/CampaignView.vue'),
    meta: {
      layout: 'horizontal',
      resource: 'Ad',
      navActiveLink: adRouteNames.campaign,
    },
    props: {
      isRegister: true,
    },
  },
]

export default [
  {
    path: '/ad/:adId',
    name: adRouteNames.root,
    redirect: '/ad/:adId/campaign',
    component: () => import('@/views/pages/ad/AdHome.vue'),
    children: [
      {
        path: 'account',
        name: adRouteNames.account,
        component: () => import('@/views/pages/ad/account/AdAccountView.vue'),
        meta: {
          layout: 'horizontal',
          resource: 'Ad',
          contentClass: 'ad-view',
        },
        beforeEnter: checkRoute,
      },
      ...campaign,
    ],
  },
]
