import useJwt from '@/auth/jwt/useJwt'
import jwt from 'jsonwebtoken'
import { channelRouteNames } from '@/router/routes/channel'
import { adRouteNames } from '@/router/routes/ad'
import {
  clearAll, setAdAccounts, setChannelAccounts, setLastAccount, setUserData,
} from '@/auth/storage/handlers'

export default {
  namespaced: true,
  state: {
    initialEmail: '',
  },
  getters: {},
  mutations: {
    setInitialEmail(state, email) {
      state.initialEmail = email
    },
  },
  actions: {
    onSignUp(ctx, email) {
      ctx.commit('setInitialEmail', email)
    },
    onAuthenticated(ctx, response) {
      // 로그인 완료 후 유저 정보 로컬 스토리지에 저장
      const {
        accessToken, refreshToken, channelAccounts, adAccounts,
      } = response.data.payload
      useJwt.setToken(accessToken)
      useJwt.setRefreshToken(refreshToken)
      const decoded = jwt.decode(accessToken)
      const channelAccountsSave = channelAccounts.map(a => ({ ...a, type: channelRouteNames.root }))
      const adAccountSave = adAccounts.map(a => ({ ...a, type: adRouteNames.root }))
      const accounts = [...channelAccountsSave.filter(a => a.status !== 'DISABLED'), ...adAccountSave.filter(a => a.status !== 'DISABLED')]
      if (accounts.length > 0) {
        const recentAccount = accounts.reduce((last, current) => (last.createdAt > current.createdAt ? last : current))
        setLastAccount(recentAccount)
      }
      setUserData(decoded)
      setChannelAccounts(channelAccountsSave)
      setAdAccounts(adAccountSave)
      return decoded
    },
    signOut() {
      // 로그아웃 시 로컬 스토리지에 저장된 정보들 일괄삭제
      clearAll()
    },
  },
}
