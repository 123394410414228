import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import slotStoreModule from '@/store/projectX/slotStoreModule'
import accountStoreModule from '@/store/projectX/accountStoreModule'
import authStoreModule from '@/store/projectX/authStoreModule'
import campaignStoreModule from '@/store/projectX/campaignStoreModule'
import adminStoreModule from '@/store/projectX/adminStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth: authStoreModule,
    slot: slotStoreModule,
    campaign: campaignStoreModule,
    account: accountStoreModule,
    admin: adminStoreModule,
  },
  strict: process.env.DEV,
})
