import axios from '@axios'
import { queryEndpoint, campaignEndpoint } from '@/store/projectX/queryEndpoint'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCampaignList(ctx, { adAccountId, params }) {
      return new Promise((resolve, reject) => {
        axios
          .get(campaignEndpoint.list(adAccountId), { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCampaign(ctx, { adAccountId, campaignId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(campaignEndpoint.view({ adAccountId, id: campaignId }))
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createCampaign(ctx, campaign) {
      return new Promise((resolve, reject) => {
        axios
          .post(campaignEndpoint.list(campaign.adAccountId), campaign)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCampaign(ctx, campaign) {
      return new Promise((resolve, reject) => {
        axios
          .put(campaignEndpoint.view(campaign), campaign)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCampaignStatus(ctx, campaign) {
      return new Promise((resolve, reject) => {
        axios
          .patch(campaignEndpoint.view(campaign), { status: campaign.status })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCampaign(ctx, campaign) {
      return new Promise((resolve, reject) => {
        axios
          .delete(campaignEndpoint.view(campaign))
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchChannels() {
      return new Promise((resolve, reject) => {
        axios
          .get(queryEndpoint.channels)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    reviewCampaign(ctx, { campaign, review }) {
      return new Promise((resolve, reject) => {
        axios
          .post(campaignEndpoint.review(campaign), review)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    requestReview(ctx, { campaign }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(campaignEndpoint.review(campaign))
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createCampaignContent(ctx, { campaign, content }) {
      return new Promise((resolve, reject) => {
        axios
          .post(campaignEndpoint.view(campaign), content)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCampaignContent(ctx, { campaign, content }) {
      return new Promise((resolve, reject) => {
        axios
          .put(campaignEndpoint.content(campaign, content), content)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadFile(ctx, { campaign, content, file }) {
      return new Promise((resolve, reject) => {
        axios
          .post(campaignEndpoint.attachment(campaign, content), file, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteFile(ctx, { campaign, content, file }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(campaignEndpoint.attachment(campaign, content), file)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCampaignContent(ctx, { campaign, content }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(campaignEndpoint.content(campaign, content))
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
