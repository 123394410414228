import { storageKey } from '@/auth/storage/config'
import { authRouteName } from '@/router/routes/auth'

export const channelRouteNames = {
  root: 'channel',
  account: 'channel-account',
  slot: 'channel-slot',
  slotView: 'channel-slot-view',
  slotViewCode: 'channel-slot-view-code',
  registerSlot: 'channel-slot-register',
  registerSlotComplete: 'channel-slot-register-complete',
  report: 'channel-report',
}

const checkRoute = (to, from, next) => {
  if (to.params.channelId.toString() === JSON.parse(localStorage.getItem(storageKey.lastAccount)).id.toString()) {
    return next()
  }
  const channelAccounts = JSON.parse(localStorage.getItem(storageKey.channelAccounts))
  const index = channelAccounts.findIndex(a => a.id.toString() === to.params.channelId.toString())
  if (index !== -1) {
    const account = channelAccounts[index]
    localStorage.setItem(storageKey.lastAccount, JSON.stringify(account))
    return next()
  }
  return next({ name: authRouteName.notAuthorized })
}

const slot = [
  {
    path: 'slot',
    name: channelRouteNames.slot,
    component: () => import('@/views/pages/channel/slot/SlotList.vue'),
    meta: {
      layout: 'horizontal',
      resource: 'Channel',
      navActiveLink: channelRouteNames.slot,
    },
    beforeEnter: checkRoute,
  },
  {
    path: 'slot/:slotId',
    name: channelRouteNames.slotView,
    component: () => import('@/views/pages/channel/slot/SlotView.vue'),
    meta: {
      layout: 'horizontal',
      resource: 'Channel',
      navActiveLink: channelRouteNames.slot,
    },
  },
  {
    path: 'slot/:slotId/view-code',
    name: channelRouteNames.slotViewCode,
    component: () => import('@/views/pages/channel/slot/SlotViewCode.vue'),
    meta: {
      layout: 'horizontal',
      resource: 'Channel',
      navActiveLink: channelRouteNames.slot,
    },
    props: true,
  },
  {
    path: 'slot-register',
    name: channelRouteNames.registerSlot,
    component: () => import('@/views/pages/channel/slot/SlotView.vue'),
    meta: {
      layout: 'horizontal',
      resource: 'Channel',
      navActiveLink: channelRouteNames.slot,
    },
    props: {
      isRegister: true,
    },
  },
]

export default [
  {
    path: '/channel/:channelId',
    name: channelRouteNames.root,
    redirect: '/channel/:channelId/slot',
    component: () => import('@/views/pages/channel/ChannelHome.vue'),
    children: [
      {
        path: 'account',
        name: channelRouteNames.account,
        component: () => import('@/views/pages/channel/account/ChannelAccountView.vue'),
        meta: {
          layout: 'horizontal',
          resource: 'Channel',
          navActiveLink: channelRouteNames.account,
        },
        beforeEnter: checkRoute,
      },
      ...slot,
    ],
  },
]
