import { storageKey } from '@/auth/storage/config'
import useJwt from '@/auth/jwt/useJwt'

export const setLastAccount = account => {
  localStorage.setItem(storageKey.lastAccount, JSON.stringify(account))
}

export const setUserData = userData => {
  localStorage.setItem(storageKey.userData, JSON.stringify(userData))
}

export const setChannelAccounts = accounts => {
  localStorage.setItem(storageKey.channelAccounts, JSON.stringify(accounts))
}

export const setAdAccounts = accounts => {
  localStorage.setItem(storageKey.adAccounts, JSON.stringify(accounts))
}

export const clearAll = () => {
  localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
  localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
  Object.keys(storageKey).forEach(key => {
    localStorage.removeItem(storageKey[key])
  })
}

export const getUserData = () => JSON.parse(localStorage.getItem(storageKey.userData))
export const getLastAccount = () => JSON.parse(localStorage.getItem(storageKey.lastAccount))
export const getChannelAccounts = () => JSON.parse(localStorage.getItem(storageKey.channelAccounts))
export const getAdAccounts = () => JSON.parse(localStorage.getItem(storageKey.adAccounts))
