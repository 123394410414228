import axios from '@axios'
import { setLastAccount } from '@/auth/storage/handlers'
import { accountEndpoint } from '@/store/projectX/queryEndpoint'

export default {
  namespaced: true,
  state: {
    currentAccount: {
      id: null,
      type: null,
      name: '',
      permission: null,
    },
  },
  getters: {
    permission: state => state.currentAccount.permission,
    type: state => state.currentAccount.type,
    accountId: state => state.currentAccount.id,
  },
  mutations: {
    setCurrentAccount(state, account) {
      state.currentAccount = account
    },
  },
  actions: {
    onSelectAccount(ctx, account) {
      const lastAccount = {
        id: account.id?.toString(),
        type: account.type,
        name: account.name,
        permission: account.permission,
      }
      setLastAccount(lastAccount)
      ctx.commit('setCurrentAccount', lastAccount)
    },
    fetchAdAccount(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(accountEndpoint.adView(id))
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchChannelAccount(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(accountEndpoint.channelView(id))
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createChannelAccount(ctx, accountName) {
      return new Promise((resolve, reject) => {
        axios
          .post(accountEndpoint.channel, { name: accountName })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createAdAccount(ctx, accountName) {
      return new Promise((resolve, reject) => {
        axios
          .post(accountEndpoint.ad, { name: accountName })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateChannelAccount(ctx, account) {
      return new Promise((resolve, reject) => {
        axios
          .put(accountEndpoint.channelView(account.id), { account })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateAdAccount(ctx, account) {
      return new Promise((resolve, reject) => {
        axios
          .put(accountEndpoint.adView(account.id), {
            name: account.name,
            apiKeys: account.apiKeys,
            siteUrls: account.siteUrls,
            taxNumber: account.taxNumber,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editAdAccountPermission(ctx, { accountId, memberId, permission }) {
      return new Promise((resolve, reject) => {
        axios
          .put(accountEndpoint.adMember(accountId, memberId), { permission })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editChannelAccountPermission(ctx, { accountId, memberId, permission }) {
      return new Promise((resolve, reject) => {
        axios
          .put(accountEndpoint.channelMember(accountId, memberId), { permission })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteAdAccountPermission(ctx, { accountId, memberId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(accountEndpoint.adMember(accountId, memberId))
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteChannelAccountPermission(ctx, { accountId, memberId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(accountEndpoint.channelMember(accountId, memberId))
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
