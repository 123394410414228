import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import LoadingComponent from '@/views/components/global/LoadingComponent.vue'
import ErrorComponent from '@/views/components/global/ErrorComponent.vue'
import GuidePopOver from '@/views/components/global/GuidePopOver.vue'

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component(LoadingComponent.name, LoadingComponent)
Vue.component(ErrorComponent.name, ErrorComponent)
Vue.component(GuidePopOver.name, GuidePopOver)
